import { OnInit, OnDestroy } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../../environments/environment';
import { AccountsService } from '../../core/services/accounts.service';
var OverviewComponent = /** @class */ (function () {
    function OverviewComponent(msalService, accountsService) {
        this.msalService = msalService;
        this.accountsService = accountsService;
    }
    OverviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions = [
            this.accountsService
                .accountsLoaded$
                .subscribe(function (loaded) { return _this.loaded = loaded; }),
            this.accountsService
                .accounts$
                .subscribe(function (accounts) { return _this.hasAccounts = accounts.length > 0; })
        ];
    };
    OverviewComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (sub) { return sub.unsubscribe(); });
    };
    Object.defineProperty(OverviewComponent.prototype, "isLoggedIn", {
        get: function () {
            return !!this.msalService.getUser();
        },
        enumerable: true,
        configurable: true
    });
    OverviewComponent.prototype.login = function ($event) {
        $event.preventDefault();
        this.msalService.loginRedirect(environment.adB2c.scopes, "ui_locales=nl");
    };
    return OverviewComponent;
}());
export { OverviewComponent };
