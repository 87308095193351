<div class="header-col"
     [ngClass]="{ 'header-col--sorted': sortActive }"
     (click)="onClick()">
  <span>{{ columnText }}</span>
  <div class="header-sorticons">
    <i class="fa fa-caret-up" 
       [ngClass]="{ 'hidden': ascendingSortIconHidden }"></i>
    <i class="fa fa-caret-down"
       [ngClass]="{ 'hidden': descendingSortIconHidden }" ></i>
  </div>
</div>