<div class="action-bar"
     [ngClass]="{ 'visible': actionBarVisible }">
    <div class="action-bar-content">
        <i class="fa fa-times" aria-hidden="true" (click)="close()"
           [tooltip]="'batch_actions.clear_selection' | translate" placement="top"></i>
        <span class="action-bar-text">
                {{ selectionCount}} {{ 'batch_actions.selected' | translate }}
        </span>
    </div>
    <div class="action-bar-actions">
        <i class="fa fa-trash" aria-hidden="true" (click)="deleteBatch()"
           [ngClass]="[decoratorClass]"
           [tooltip]="'batch_actions.delete_selection' | translate" placement="left" [adaptivePosition]="false" container="body"></i>
    </div>
</div>
