<div class="filter-box">
  <div class="header">
    <div class="title">
      {{ name }}
    </div>
    <div class="title-extra">max. {{ max }}</div>
  </div>
  <div class="choices">
    <div *ngFor="let expectation of expectations" class="choice">
      <label class="checkbox-container filter"
             [ngClass]="[name | dashify, expectation.name | dashify]">
        <input
          type="checkbox"
          [id]="expectation.id"
          [ngModel]="expectation.selected"
          (change)="onToggle($event, expectation, $event.target.checked)"
          [disabled]="!expectation.selected && !canSelect"
        />
        <span class="checkmark"></span>
      </label>

      <label
        class="checkbox-label filter"
        [ngClass]="[name | dashify, expectation.name | dashify]"
        [class.selected]="expectation.selected"
        [class.disabled]="!expectation.selected && !canSelect"
        [for]="expectation.id"
      >
        {{ getExpectationNameKey(expectation.id) | translate }}
      </label>
    </div>
    <kazi-spinner *ngIf="loading"></kazi-spinner>
  </div>
  <div class="actions">
    <a href (click)="onReset($event)">
      <i class="fa fa-undo action-icon"></i>
      <label>Clear filter</label>
    </a>
  </div>
</div>
