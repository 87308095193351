import { Injectable } from '@angular/core';
import { Router, NavigationEnd, RouterEvent } from '@angular/router';
import { Subject, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

export enum BatchSelectionType {
    Talents,
    Jobs,
    Reflections
}

@Injectable({ providedIn: 'root' })
export class BatchActionService {

    actionBarVisible$: BehaviorSubject<boolean>;
    delete$: Subject<{ selectionType: BatchSelectionType, selection: Array<string> }>;
    selectedItems$: BehaviorSubject<Array<string>>;
    selection: Array<string>;
    selectionType: BatchSelectionType;

    constructor(private router: Router) {
        this.actionBarVisible$ = new BehaviorSubject(false);
        this.selectedItems$ = new BehaviorSubject([]);
        this.delete$ = new Subject();
        this.selection = [];

        this.router.events
            .pipe(filter(e => e instanceof NavigationEnd))
            .subscribe((e: RouterEvent) => {

                this.deSelectAll();

                if (e.url === '/talents') {
                    this.selectionType = BatchSelectionType.Talents;
                } else if (e.url === '/jobs') {
                    this.selectionType = BatchSelectionType.Jobs;
                } else if (e.url === '/reflections') {
                    this.selectionType = BatchSelectionType.Reflections;
                }
            });
    }

    toggleItem(id: string) {
        const idx = this.selection.indexOf(id);
        if (idx >= 0) {
            this.selection.splice(idx, 1);
        } else {
            this.selection.push(id);
        }

        this.selectedItems$.next(this.selection);
        this.actionBarVisible$.next(this.selection.length > 0);
    }

    deSelectAll() {
        this.selection = [];
        this.selectedItems$.next(this.selection);
        this.actionBarVisible$.next(false);
    }

    delete() {
        this.delete$.next({ selectionType: this.selectionType, selection: this.selection });
    }
}
