import { OnInit, EventEmitter } from '@angular/core';
var ColumnHeaderComponent = /** @class */ (function () {
    function ColumnHeaderComponent() {
        this.sort = new EventEmitter();
    }
    Object.defineProperty(ColumnHeaderComponent.prototype, "currentSort", {
        set: function (sortOn) {
            var sort = sortOn;
            this.currentSortProp = sort.substring(1);
            this.ascending = sort.indexOf('+') === 0;
            this.sortActive = this.currentSortProp.indexOf(this.sortProp) >= 0;
            this.ascendingSortIconHidden = this.currentSortProp === this.sortProp && this.ascending === false;
            this.descendingSortIconHidden = this.currentSortProp === this.sortProp && this.ascending === true;
        },
        enumerable: true,
        configurable: true
    });
    ColumnHeaderComponent.prototype.ngOnInit = function () {
        this.ascending = false;
    };
    ColumnHeaderComponent.prototype.getSortString = function () {
        return "" + (this.ascending ? '+' : '-') + this.sortProp;
    };
    ColumnHeaderComponent.prototype.onClick = function () {
        if (this.sortProp === this.currentSortProp) {
            this.ascending = !this.ascending;
            this.sort.emit(this.getSortString());
            return;
        }
        this.ascending = true;
        this.sort.emit(this.getSortString());
    };
    return ColumnHeaderComponent;
}());
export { ColumnHeaderComponent };
