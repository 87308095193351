/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./expectations.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-bootstrap/tooltip";
import * as i3 from "ngx-bootstrap/component-loader";
import * as i4 from "ngx-bootstrap/positioning";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/common";
import * as i7 from "./expectations.component";
var styles_ExpectationsComponent = [i0.styles];
var RenderType_ExpectationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExpectationsComponent, data: {} });
export { RenderType_ExpectationsComponent as RenderType_ExpectationsComponent };
function View_ExpectationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 2, "img", [["placement", "bottom"]], [[8, "src", 4]], null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.TooltipDirective, [i1.ViewContainerRef, i3.ComponentLoaderFactory, i2.TooltipConfig, i1.ElementRef, i1.Renderer2, i4.PositioningService], { tooltip: [0, "tooltip"], placement: [1, "placement"] }, null), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform((("expectations." + _v.context.$implicit.expectationId) + ".name"))); var currVal_2 = "bottom"; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.iconUrl; _ck(_v, 0, 0, currVal_0); }); }
export function View_ExpectationsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "expectations"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "logos__top2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExpectationsComponent_1)), i1.ɵdid(3, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "names"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "names__top2"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 16777216, null, null, 2, "div", [["class", "names__others"], ["container", "body"], ["placement", "bottom"]], null, null, null, null, null)), i1.ɵdid(9, 212992, null, 0, i2.TooltipDirective, [i1.ViewContainerRef, i3.ComponentLoaderFactory, i2.TooltipConfig, i1.ElementRef, i1.Renderer2, i4.PositioningService], { adaptivePosition: [0, "adaptivePosition"], tooltip: [1, "tooltip"], placement: [2, "placement"], container: [3, "container"] }, null), (_l()(), i1.ɵted(10, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.top2Expectations; _ck(_v, 3, 0, currVal_0); var currVal_2 = false; var currVal_3 = _co.otherExpectationsNames; var currVal_4 = "bottom"; var currVal_5 = "body"; _ck(_v, 9, 0, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.top2ExpectationsNames)); _ck(_v, 6, 0, currVal_1); var currVal_6 = _co.otherExpectationsNames; _ck(_v, 10, 0, currVal_6); }); }
export function View_ExpectationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "kazi-expectations", [], null, null, null, View_ExpectationsComponent_0, RenderType_ExpectationsComponent)), i1.ɵprd(131584, null, i5.TranslatePipe, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), i1.ɵdid(2, 245760, null, 0, i7.ExpectationsComponent, [i5.TranslateService, i5.TranslatePipe], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ExpectationsComponentNgFactory = i1.ɵccf("kazi-expectations", i7.ExpectationsComponent, View_ExpectationsComponent_Host_0, { expectations: "expectations", type: "type" }, {}, []);
export { ExpectationsComponentNgFactory as ExpectationsComponentNgFactory };
