<div class="shell">
    <div class="sidebar">
        <kazi-sidebar></kazi-sidebar>
    </div>
    <main class="main">
        <div class="header">
            <kazi-header></kazi-header>
        </div>
        <div class="content"
             [ngClass]="{ 'action-bar-visible': actionBarVisible }"
             data-simplebar>
            <router-outlet></router-outlet>
        </div>
        <kazi-batch-action-bar></kazi-batch-action-bar>
    </main>
</div>

