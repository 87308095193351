import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
var UserSettingsService = /** @class */ (function () {
    function UserSettingsService() {
        this.currentAccountKey = 'currentAccount';
        this.subscriptions = [];
        this.currentAccount$ = new BehaviorSubject(null);
    }
    UserSettingsService.prototype.clear = function () {
        this.subscriptions.forEach(function (sub) { return sub.unsubscribe(); });
        this.currentAccount$.next(null);
        localStorage.removeItem(this.currentAccountKey);
    };
    UserSettingsService.prototype.restore = function () {
        var _this = this;
        var currentAccountJson = localStorage.getItem(this.currentAccountKey);
        if (currentAccountJson) {
            this.currentAccount$.next(JSON.parse(currentAccountJson));
        }
        this.currentAccount$.pipe(filter(function (account) { return !!account; })).subscribe(function (account) { return localStorage.setItem(_this.currentAccountKey, JSON.stringify(account)); });
    };
    UserSettingsService.prototype.isAccountSelected = function () {
        return !!this.currentAccount$.getValue();
    };
    UserSettingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserSettingsService_Factory() { return new UserSettingsService(); }, token: UserSettingsService, providedIn: "root" });
    return UserSettingsService;
}());
export { UserSettingsService };
