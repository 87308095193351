import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';

import { JobScan } from '../reflections/reflections.component';
import { DeleteModalComponent, DeleteModalProps } from '../../shared/components/delete-modal/delete-modal.component';
import { UserSettingsService } from '../../core/services/user-settings.service';
import { IdentifyingClient } from '../../shared/api/identifying-api.generated';
import { BatchActionService } from '../../core/services/batch-action.service';

@Component({
  selector: 'kazi-reflection',
  templateUrl: './reflection.component.html',
  styleUrls: ['./reflection.component.scss']
})
export class ReflectionComponent implements OnInit, OnDestroy {

  workValuesExpectationTypeId = 1;
  teamRolesExpectationTypeId = 2;

  domains: string;
  subscriptions: Subscription[];
  hasExpectations: boolean;

  @Input() jobScan: JobScan;
  @Output() deleted = new EventEmitter<JobScan>();

  constructor(private identifyingClient: IdentifyingClient,
    private router: Router,
    private translate: TranslateService,
    private userSettingsService: UserSettingsService,
    private modalService: BsModalService,
    private batchActionService: BatchActionService) {
    this.subscriptions = [];
  }

  ngOnInit() {
    this.domains = this.jobScan.domains.map(dom => dom.name[this.translate.currentLang]).join(', ');
    this.hasExpectations = this.jobScan.expectations.length === 14;
  }

  select() {
    this.batchActionService.toggleItem(this.jobScan.id);
  }

  isSelected(): boolean {
    return this.batchActionService.selection.indexOf(this.jobScan.id) > -1;
  }

  showReport($event: Event, jobScanId: string) {

    $event.preventDefault();
    $event.stopImmediatePropagation();

    this.router.navigate(['reflections', jobScanId, 'report']);
  }

  printReport($event: Event, jobScanId: string) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    const url = `/reflections/${jobScanId}/report/print`;
    window.open(url, '_blank');
  }

  showDeleteConfirmationModal($event, jobScanId: string) {

    $event.preventDefault();
    $event.stopImmediatePropagation();

    const header = this.translate.instant('jobscans.delete_1_modal_header');
    const message = this.translate.instant('jobscans.delete_1_modal_content',
      { accountName: this.userSettingsService.currentAccount$.getValue().name });
    const props = new DeleteModalProps(header, message);
    const initialState = { props };

    this.modalService.show(DeleteModalComponent, { initialState });

    const deleteSub = props.confirm$
      .pipe(switchMap(() => this.identifyingClient.deleteJobScans(jobScanId)))
      .subscribe(() => this.deleted.next(this.jobScan));

    this.subscriptions.push(deleteSub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
