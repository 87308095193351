/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./batch-action-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ngx-bootstrap/tooltip";
import * as i4 from "ngx-bootstrap/component-loader";
import * as i5 from "ngx-bootstrap/positioning";
import * as i6 from "@ngx-translate/core";
import * as i7 from "./batch-action-bar.component";
import * as i8 from "../../core/services/batch-action.service";
import * as i9 from "../../core/services/user-settings.service";
import * as i10 from "@angular/router";
var styles_BatchActionBarComponent = [i0.styles];
var RenderType_BatchActionBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BatchActionBarComponent, data: {} });
export { RenderType_BatchActionBarComponent as RenderType_BatchActionBarComponent };
export function View_BatchActionBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "action-bar"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "visible": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "action-bar-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 16777216, null, null, 2, "i", [["aria-hidden", "true"], ["class", "fa fa-times"], ["placement", "top"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 212992, null, 0, i3.TooltipDirective, [i1.ViewContainerRef, i4.ComponentLoaderFactory, i3.TooltipConfig, i1.ElementRef, i1.Renderer2, i5.PositioningService], { tooltip: [0, "tooltip"], placement: [1, "placement"] }, null), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 2, "span", [["class", "action-bar-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " ", " "])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 6, "div", [["class", "action-bar-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 16777216, null, null, 5, "i", [["aria-hidden", "true"], ["class", "fa fa-trash"], ["container", "body"], ["placement", "left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteBatch() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(14, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpad(15, 1), i1.ɵdid(16, 212992, null, 0, i3.TooltipDirective, [i1.ViewContainerRef, i4.ComponentLoaderFactory, i3.TooltipConfig, i1.ElementRef, i1.Renderer2, i5.PositioningService], { adaptivePosition: [0, "adaptivePosition"], tooltip: [1, "tooltip"], placement: [2, "placement"], container: [3, "container"] }, null), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "action-bar"; var currVal_1 = _ck(_v, 3, 0, _co.actionBarVisible); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("batch_actions.clear_selection")); var currVal_3 = "top"; _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_6 = "fa fa-trash"; var currVal_7 = _ck(_v, 15, 0, _co.decoratorClass); _ck(_v, 14, 0, currVal_6, currVal_7); var currVal_8 = false; var currVal_9 = i1.ɵunv(_v, 16, 1, i1.ɵnov(_v, 17).transform("batch_actions.delete_selection")); var currVal_10 = "left"; var currVal_11 = "body"; _ck(_v, 16, 0, currVal_8, currVal_9, currVal_10, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.selectionCount; var currVal_5 = i1.ɵunv(_v, 9, 1, i1.ɵnov(_v, 10).transform("batch_actions.selected")); _ck(_v, 9, 0, currVal_4, currVal_5); }); }
export function View_BatchActionBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kazi-batch-action-bar", [], null, null, null, View_BatchActionBarComponent_0, RenderType_BatchActionBarComponent)), i1.ɵdid(1, 245760, null, 0, i7.BatchActionBarComponent, [i8.BatchActionService, i9.UserSettingsService, i10.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BatchActionBarComponentNgFactory = i1.ɵccf("kazi-batch-action-bar", i7.BatchActionBarComponent, View_BatchActionBarComponent_Host_0, {}, {}, []);
export { BatchActionBarComponentNgFactory as BatchActionBarComponentNgFactory };
