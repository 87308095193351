import localeNlBe from '@angular/common/locales/nl-BE';
import * as Sentry from '@sentry/browser';
import { environment } from 'src/environments/environment';
import { registerLocaleData } from '@angular/common';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
Sentry.init({
    dsn: environment.sentry.dsn,
    ignoreErrors: [/ResizeObserver/]
});
registerLocaleData(localeNlBe);
var ɵ0 = environment.googleTagManagerId;
var AppModule = /** @class */ (function () {
    function AppModule(gtmService) {
        if (environment.production) {
            gtmService.addGtmToDom();
        }
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
