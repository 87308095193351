import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ShellComponent } from './shell/shell.component';
import { OverviewComponent } from './overview/overview.component';

import { shellRoutes as TalentScanRoutes } from '../talentscans/talentscans-routing.module';
import { shellRoutes as JobScanRoutes } from '../jobscans/jobscans-routing.module';
import { shellRoutes as ReflectionScanRoutes } from '../reflections/reflections-routing.module';

const routes: Routes = [
  {
    path: '', component: ShellComponent,
    children: [
      { path: '', component: OverviewComponent, pathMatch: 'full' },
      ...TalentScanRoutes,
      ...JobScanRoutes,
      ...ReflectionScanRoutes
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShellRoutingModule { }
