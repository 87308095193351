import { BehaviorSubject } from 'rxjs';
var JobScansFilter = /** @class */ (function () {
    function JobScansFilter() {
        this.workValues = [];
        this.teamRoles = [];
        this.domains = [];
    }
    Object.defineProperty(JobScansFilter.prototype, "workValueIds", {
        get: function () {
            return this.workValues.length > 0 ? this.workValues.join(',') : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobScansFilter.prototype, "teamRoleIds", {
        get: function () {
            return this.teamRoles.length > 0 ? this.teamRoles.join(',') : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobScansFilter.prototype, "domainIds", {
        get: function () {
            return this.domains.length > 0 ? this.domains.join(',') : undefined;
        },
        enumerable: true,
        configurable: true
    });
    return JobScansFilter;
}());
export { JobScansFilter };
var JobScansFilterService = /** @class */ (function () {
    function JobScansFilterService() {
        this.filter$ = new BehaviorSubject(new JobScansFilter());
    }
    return JobScansFilterService;
}());
export { JobScansFilterService };
