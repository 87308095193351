import { HttpClient } from '@angular/common/http';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MsalModule } from '@azure/msal-angular';
import { environment } from '../../environments/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ExpectationsComponent } from './components/expectations/expectations.component';
import { ColumnHeaderComponent } from './components/column-header/column-header.component';
import { DeleteModalComponent } from './components/delete-modal/delete-modal.component';
import { ImageDirective } from './components/image/image.directive';
import { ReportComponent } from './components/report/report.component';
import { ExpectationsFilterComponent } from './components/expectations-filter/expectations-filter.component';
import { DomainsFilterComponent } from './components/domains-filter/domains-filter.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { DashifyPipe } from './pipes/dashify.pipe';
var authority = "" + environment.adB2c.identityProviderUrl + environment.adB2c.tenant + "/" +
    ("" + environment.adB2c.signUpSignInPolicyId);
export function msalLogger(logLevel, message, piiEnabled) {
    console.log('MSAL [' + logLevel + '] : ' + message);
}
export function windowFactory() {
    return window;
}
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient) {
    return new TranslateHttpLoader(httpClient);
}
export var protectedResourceMap = [
    [environment.api.identifyingUrl, environment.adB2c.scopes],
    [environment.api.taggingUrl, environment.adB2c.scopes],
    [environment.api.administratorUrl, environment.adB2c.scopes]
];
var isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
var msalModule = MsalModule.forRoot({
    clientID: environment.api.clientId,
    authority: authority,
    validateAuthority: false,
    redirectUri: environment.baseUrl,
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE,
    postLogoutRedirectUri: environment.baseUrl,
    navigateToLoginRequestUrl: false,
    popUp: false,
    consentScopes: environment.adB2c.scopes,
    unprotectedResources: ['/assets'],
    protectedResourceMap: protectedResourceMap,
    logger: msalLogger,
    correlationId: '1234',
    level: environment.msalLogLevel,
    piiLoggingEnabled: true
});
var components = [ExpectationsComponent,
    ColumnHeaderComponent,
    ReportComponent,
    DeleteModalComponent,
    ExpectationsFilterComponent,
    DomainsFilterComponent,
    SpinnerComponent];
var directives = [ImageDirective];
var pipes = [DashifyPipe];
var bootstrapModules = [TooltipModule, ModalModule];
var ɵ0 = environment.api.identifyingUrl, ɵ1 = environment.api.taggingUrl, ɵ2 = environment.api.administratorUrl;
var SharedModule = /** @class */ (function () {
    function SharedModule() {
    }
    return SharedModule;
}());
export { SharedModule };
export { ɵ0, ɵ1, ɵ2 };
