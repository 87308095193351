/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./delete-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./delete-modal.component";
import * as i3 from "ngx-bootstrap/modal";
var styles_DeleteModalComponent = [i0.styles];
var RenderType_DeleteModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DeleteModalComponent, data: {} });
export { RenderType_DeleteModalComponent as RenderType_DeleteModalComponent };
export function View_DeleteModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", "\n"])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.props.headerText; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.props.contentText; _ck(_v, 4, 0, currVal_1); }); }
export function View_DeleteModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kazi-delete-modal", [], null, null, null, View_DeleteModalComponent_0, RenderType_DeleteModalComponent)), i1.ɵdid(1, 114688, null, 0, i2.DeleteModalComponent, [i3.BsModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DeleteModalComponentNgFactory = i1.ɵccf("kazi-delete-modal", i2.DeleteModalComponent, View_DeleteModalComponent_Host_0, { props: "props" }, {}, []);
export { DeleteModalComponentNgFactory as DeleteModalComponentNgFactory };
