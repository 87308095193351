<div class="sidebar">
  <div class="logo">
      <a routerLink="">
        <img src="/assets/images/KAZI_LogoOnDarkBackground.svg" />
      </a>
  </div>
  <div class="accounts-wrapper">
    <div class="accounts-scroll-container">
      <div class="accounts" data-simplebar>
        <div *ngFor="let account of accounts"
            class="account">
          <a class="account__logo" (click)="changeToAccount(account)">
            <figure [ngClass]="{ 'selected': isAccountSelected(account) }">
              <img [src]="account.logoUrl"
                  [ngClass]="{ 'selected': isAccountSelected(account) }"
                  default="assets/images/KAZI_LogoOnLightBackground.svg"
                  [tooltip]="account.name" placement="right" [adaptivePosition]="false" container="body">
            </figure>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="actions">
    <div class="user" *ngIf="isLoggedIn">
      <div class="user__avatar">
        <span class="fa fa-user"></span>
      </div>
      <div class="user__name">
        {{ currentUser.name }}
      </div>
    </div>
    <div class="actions__item" *ngIf="isLoggedIn">
      <a title="Log out" (click)="logout()"
         [tooltip]="'actions.log_out' | translate"
         placement="right">
          <i class="fa fa-sign-out"></i>
      </a>
    </div>
    <div class="actions__item" *ngIf="!isLoggedIn">
      <a title="Log in" (click)="login()">
          <i class="fa fa-sign-in"></i>
      </a>
    </div>
  </div>
</div>
