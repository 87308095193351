import { BehaviorSubject } from 'rxjs';
import { TaggingClient } from '../../shared/api/tagging-api.generated';
import { IdentifyingClient } from '../../shared/api/identifying-api.generated';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/api/tagging-api.generated";
import * as i2 from "../../shared/api/identifying-api.generated";
var PortalService = /** @class */ (function () {
    function PortalService(taggingClient, identifyingClient) {
        this.taggingClient = taggingClient;
        this.identifyingClient = identifyingClient;
        this.expectations$ = new BehaviorSubject(null);
        this.domains$ = new BehaviorSubject(null);
        this.loadExpectations();
        this.loadDomains();
    }
    PortalService.prototype.loadExpectations = function () {
        var _this = this;
        this.taggingClient.getExpectations()
            .subscribe(function (expectations) { return _this.expectations$.next(expectations); });
    };
    PortalService.prototype.loadDomains = function () {
        var _this = this;
        this.identifyingClient.getDomains()
            .subscribe(function (domains) { return _this.domains$.next(domains); });
    };
    PortalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PortalService_Factory() { return new PortalService(i0.ɵɵinject(i1.TaggingClient), i0.ɵɵinject(i2.IdentifyingClient)); }, token: PortalService, providedIn: "root" });
    return PortalService;
}());
export { PortalService };
