import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
var SubscriptionInterceptor = /** @class */ (function () {
    function SubscriptionInterceptor() {
    }
    SubscriptionInterceptor.prototype.intercept = function (request, next) {
        request = request.clone({
            headers: request.headers.set('Ocp-Apim-Subscription-Key', environment.api.subscriptionKey)
        });
        return next.handle(request);
    };
    SubscriptionInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SubscriptionInterceptor_Factory() { return new SubscriptionInterceptor(); }, token: SubscriptionInterceptor, providedIn: "root" });
    return SubscriptionInterceptor;
}());
export { SubscriptionInterceptor };
