import { Router, NavigationEnd } from '@angular/router';
import { Subject, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export var BatchSelectionType;
(function (BatchSelectionType) {
    BatchSelectionType[BatchSelectionType["Talents"] = 0] = "Talents";
    BatchSelectionType[BatchSelectionType["Jobs"] = 1] = "Jobs";
    BatchSelectionType[BatchSelectionType["Reflections"] = 2] = "Reflections";
})(BatchSelectionType || (BatchSelectionType = {}));
var BatchActionService = /** @class */ (function () {
    function BatchActionService(router) {
        var _this = this;
        this.router = router;
        this.actionBarVisible$ = new BehaviorSubject(false);
        this.selectedItems$ = new BehaviorSubject([]);
        this.delete$ = new Subject();
        this.selection = [];
        this.router.events
            .pipe(filter(function (e) { return e instanceof NavigationEnd; }))
            .subscribe(function (e) {
            _this.deSelectAll();
            if (e.url === '/talents') {
                _this.selectionType = BatchSelectionType.Talents;
            }
            else if (e.url === '/jobs') {
                _this.selectionType = BatchSelectionType.Jobs;
            }
            else if (e.url === '/reflections') {
                _this.selectionType = BatchSelectionType.Reflections;
            }
        });
    }
    BatchActionService.prototype.toggleItem = function (id) {
        var idx = this.selection.indexOf(id);
        if (idx >= 0) {
            this.selection.splice(idx, 1);
        }
        else {
            this.selection.push(id);
        }
        this.selectedItems$.next(this.selection);
        this.actionBarVisible$.next(this.selection.length > 0);
    };
    BatchActionService.prototype.deSelectAll = function () {
        this.selection = [];
        this.selectedItems$.next(this.selection);
        this.actionBarVisible$.next(false);
    };
    BatchActionService.prototype.delete = function () {
        this.delete$.next({ selectionType: this.selectionType, selection: this.selection });
    };
    BatchActionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BatchActionService_Factory() { return new BatchActionService(i0.ɵɵinject(i1.Router)); }, token: BatchActionService, providedIn: "root" });
    return BatchActionService;
}());
export { BatchActionService };
