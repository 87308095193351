<div *ngIf="!print">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li *ngIf="talentScanReport || isCompareTalentWithJob" class="breadcrumb-item">
                <a [routerLink]="'/talents'">{{ 'header.talents' | translate }}</a>
            </li>
            <li *ngIf="jobScanReport || isCompareJobWithTalent" class="breadcrumb-item">
                <a [routerLink]="'/jobs'">{{ 'header.jobscans' | translate }}</a>
            </li>
            <li *ngIf="reflectionScanReport || isCompareReflectionWithTalent" class="breadcrumb-item">
                <a [routerLink]="'/reflections'">{{ 'header.reflections' | translate }}</a>
            </li>

            <li *ngIf="!isCompareReport" class="breadcrumb-item active" aria-current="page">
                {{ 'report.report' | translate }}
            </li>
            <ng-container *ngIf="isCompareReport">
                <li class="breadcrumb-item" aria-current="page">
                    <a
                        [routerLink]="(isCompareTalentWithJob ? '/talents/' : '/jobs/') + parameterService.parameters.id + '/report'">{{
                        'report.report' | translate
                        }}</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    {{ 'report.compare' | translate }}
                </li>
            </ng-container>
        </ol>
    </nav>

    <div *ngIf="loaded" class="public-link">
        <a *ngIf="talentScanReport || jobScanReport || reflectionScanReport || isCompareTalentWithJob || isCompareJobWithTalent || isCompareReflectionWithTalent"
            href="javascript:void(0)" (click)="openPublicLink()">Open Public Report</a>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <a *ngIf="isEngage && (isCompareTalentWithJob || isCompareJobWithTalent || isCompareReflectionWithTalent)"
            (click)="openPublicDetailLink()" href="javascript:void(0)">Open
            Public Detail Report</a>

    </div>
    <div *ngIf="loaded" class="report">

        <div *ngIf="talentScanReport">
            <kazi-talent-spider-report (error)="onError($event)"
                (compare)="compareTalentWithJob($event)"></kazi-talent-spider-report>
        </div>
        <div *ngIf="jobScanReport">
            <kazi-jobscan-spider-report (error)="onError($event)"
                (compare)="compareJobWithTalent($event)"></kazi-jobscan-spider-report>
        </div>
        <div *ngIf="reflectionScanReport">
            <kazi-jobscan-spider-report (error)="onError($event)"
                (compare)="compareJobWithTalent($event)"></kazi-jobscan-spider-report>
        </div>
        <div *ngIf="isCompareReport">
            <kazi-compare-report-wrapper (error)="onError($event)"></kazi-compare-report-wrapper>
        </div>
        <div *ngIf="error" class="report-error">
            <div class="alert alert-danger" role="alert">
                <div *ngIf="talentScanReport">
                    {{ 'report.no_expectations_found_for_talent' | translate }}
                </div>
                <div *ngIf="jobScanReport">
                    {{ 'report.no_expectations_found_for_jobscan' | translate }}
                </div>
                <div *ngIf="reflectionScanReport">
                    {{ 'report.no_expectations_found_for_jobscan' | translate }}
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="loaded && print">
    <div *ngIf="talentScanReport">
        <kazi-talent-spider-report (error)="onError($event)" (loaded)="showPrintDialog()"></kazi-talent-spider-report>
    </div>
    <div *ngIf="jobScanReport">
        <kazi-jobscan-spider-report (error)="onError($event)" (loaded)="showPrintDialog()"></kazi-jobscan-spider-report>
    </div>
    <div *ngIf="reflectionScanReport">
        <kazi-jobscan-spider-report (error)="onError($event)" (loaded)="showPrintDialog()"></kazi-jobscan-spider-report>
    </div>
</div>