import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { KaziReportsModule } from 'kazi-reports-prod';
import { LogLevel } from 'msal';

import { MsalModule } from '@azure/msal-angular';
import { MsalInterceptor } from '@azure/msal-angular';

import { environment } from '../../environments/environment';

import { IdentifyingClient, API_BASE_URL_IDENTIFYING } from './api/identifying-api.generated';
import { TaggingClient, API_BASE_URL_TAGGING } from './api/tagging-api.generated';
import { AdministratorClient, API_BASE_URL_ADMINISTRATOR } from './api/administrator-api.generated';
import { SubscriptionInterceptor } from '../core/interceptors/subscription-interceptor';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ExpectationsComponent } from './components/expectations/expectations.component';
import { ColumnHeaderComponent } from './components/column-header/column-header.component';
import { DeleteModalComponent } from './components/delete-modal/delete-modal.component';
import { ImageDirective } from './components/image/image.directive';
import { ReportComponent } from './components/report/report.component';
import { ExpectationsFilterComponent } from './components/expectations-filter/expectations-filter.component';
import { DomainsFilterComponent } from './components/domains-filter/domains-filter.component';
import { SpinnerComponent } from './components/spinner/spinner.component';

import { DashifyPipe } from './pipes/dashify.pipe';
import { ThemeService } from 'ng2-charts';

const authority = `${environment.adB2c.identityProviderUrl}${environment.adB2c.tenant}/` +
  `${environment.adB2c.signUpSignInPolicyId}`;

export function msalLogger(logLevel: LogLevel, message: any, piiEnabled: any) {
  console.log('MSAL [' + logLevel + '] : ' + message);
}

export function windowFactory() {
  return window;
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export const protectedResourceMap: [string, string[]][] =
  [
    [environment.api.identifyingUrl, environment.adB2c.scopes],
    [environment.api.taggingUrl, environment.adB2c.scopes],
    [environment.api.administratorUrl, environment.adB2c.scopes]
  ];

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

const msalModule = MsalModule.forRoot({
  clientID: environment.api.clientId,
  authority,
  validateAuthority: false,
  redirectUri: environment.baseUrl,
  cacheLocation: 'localStorage',
  storeAuthStateInCookie: isIE,   // set to true for IE 11
  postLogoutRedirectUri: environment.baseUrl,
  navigateToLoginRequestUrl: false,
  popUp: false,
  consentScopes: environment.adB2c.scopes,
  unprotectedResources: ['/assets'],
  protectedResourceMap,
  logger: msalLogger,
  correlationId: '1234',
  level: environment.msalLogLevel,
  piiLoggingEnabled: true
});

const components = [ExpectationsComponent,
  ColumnHeaderComponent,
  ReportComponent,
  DeleteModalComponent,
  ExpectationsFilterComponent,
  DomainsFilterComponent,
  SpinnerComponent];

const directives = [ImageDirective];
const pipes = [DashifyPipe];


const bootstrapModules = [TooltipModule, ModalModule];

@NgModule({
  declarations: [...components, ...directives, ...pipes],
  imports: [HttpClientModule,
    msalModule,
    RouterModule.forChild([]),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),

    FormsModule, CommonModule,
    InfiniteScrollModule,
    KaziReportsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })],
  providers: [
    { provide: API_BASE_URL_IDENTIFYING, useValue: environment.api.identifyingUrl },
    { provide: API_BASE_URL_TAGGING, useValue: environment.api.taggingUrl },
    { provide: API_BASE_URL_ADMINISTRATOR, useValue: environment.api.administratorUrl },
    { provide: HTTP_INTERCEPTORS, useClass: SubscriptionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: 'window', useFactory: windowFactory },
    IdentifyingClient,
    TaggingClient,
    AdministratorClient,
    ThemeService
  ],
  exports: [...bootstrapModules,
    FormsModule,
    InfiniteScrollModule,
    TranslateModule,
    KaziReportsModule,
  ...components,
  ...directives],
  entryComponents: [
    DeleteModalComponent
  ]
})

export class SharedModule { }
