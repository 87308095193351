<div class="filter-box">
  <div class="header">
    <div class="title">
      {{ name }}
    </div>
    <div class="title-extra"></div>
  </div>
  <div class="choices">
    <div *ngFor="let domain of domains" class="choice">
      <label class="checkbox-container filter domains"
             [ngClass]="[domain.name | dashify]">
        <input
          type="checkbox"
          [id]="domain.id"
          [ngModel]="domain.selected"
          (change)="onToggle($event, domain, $event.target.checked)"
        />
        <span class="checkmark"></span>
      </label>

      <label
        class="checkbox-label filter domains"
        [ngClass]="[domain.name | dashify]"
        [class.selected]="domain.selected"
        [for]="domain.id"
      >
        {{ getDomainNameKey(domain.id) | translate }}
      </label>
    </div>
    <kazi-spinner *ngIf="loading"></kazi-spinner>
  </div>
  <div class="actions">
    <a href (click)="onReset($event)">
      <i class="fa fa-undo action-icon"></i>
      <label>Clear filter</label>
    </a>
  </div>
</div>
