<div class="spinner-container">
  <svg
    class="lds-message"
    width="100"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    preserveAspectRatio="xMidYMid"
  >
    <g transform="translate(25 10)">
      <circle
        cx="0"
        cy="0"
        r="6"
        fill="#4396CF"
        transform="scale(0.452133 0.452133)"
      >
        <animateTransform
          attributeName="transform"
          type="scale"
          begin="-0.3333333333333333s"
          calcMode="spline"
          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
          values="0;1;0"
          keyTimes="0;0.5;1"
          dur="1s"
          repeatCount="indefinite"
        ></animateTransform>
      </circle>
    </g>
    <g transform="translate(50 10)">
      <circle
        cx="0"
        cy="0"
        r="6"
        fill="#ee5a2f"
        transform="scale(0.88795 0.88795)"
      >
        <animateTransform
          attributeName="transform"
          type="scale"
          begin="-0.16666666666666666s"
          calcMode="spline"
          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
          values="0;1;0"
          keyTimes="0;0.5;1"
          dur="1s"
          repeatCount="indefinite"
        ></animateTransform>
      </circle>
    </g>
    <g transform="translate(75 10)">
      <circle
        cx="0"
        cy="0"
        r="6"
        fill="#2a3248"
        transform="scale(0.944139 0.944139)"
      >
        <animateTransform
          attributeName="transform"
          type="scale"
          begin="0s"
          calcMode="spline"
          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
          values="0;1;0"
          keyTimes="0;0.5;1"
          dur="1s"
          repeatCount="indefinite"
        ></animateTransform>
      </circle>
    </g>
  </svg>
</div>
