<div class="header">
    <div class="nav-items">
        <div class="account">
            <div class="account__logo">
                <img [src]="currentAccount?.logoUrl || ''" default="assets/images/KAZI_LogoOnLightBackground.svg" />
            </div>
            <div class="account__name">{{ currentAccount?.name }}</div>
        </div>
        <div class="nav-item">
            <a routerLink="" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                {{ 'header.overview' | translate }}
            </a>
        </div>
        <div class="nav-item" *ngIf="hasAccounts">
            <a routerLink="/talents" routerLinkActive="active">{{ 'header.talents' | translate }}</a>
        </div>
        <div class="nav-item" *ngIf="hasAccounts">
            <a routerLink="/jobs" routerLinkActive="active">{{ 'header.jobscans' | translate }}</a>
        </div>
        <div class="nav-item" *ngIf="hasAccounts">
            <a routerLink="/reflections" routerLinkActive="active">{{ 'header.reflections' | translate }}</a>
        </div>
    </div>
    <div class="language">
        <ng-template ngFor let-lang [ngForOf]="getLangs()" let-i="index">
            <span *ngIf="i !== 0">&nbsp;|&nbsp;</span>
            <a *ngIf="translate.currentLang !== lang" href="javascript:void(0)" class="link lang-item {{ lang }}"
                (click)="clickLanguage(lang)">
                {{ lang.toUpperCase() }}
            </a>
            <span *ngIf="translate.currentLang === lang" class="current lang-item {{ lang }}">{{ lang.toUpperCase()
                }}</span>
        </ng-template>
    </div>
    <div class="version">
        <span [title]="revision">ADM {{ version }}</span>
    </div>
</div>