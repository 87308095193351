import { PipeTransform } from '@angular/core';
var DashifyPipe = /** @class */ (function () {
    function DashifyPipe() {
    }
    DashifyPipe.prototype.transform = function (value) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return value.replace(/(\s+\/\s+)|(\s+-\s+)|(\/)|(\s+)/g, '-').toLowerCase();
    };
    return DashifyPipe;
}());
export { DashifyPipe };
