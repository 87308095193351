<div class="modal-header">
  <h4 class="modal-title pull-left">{{ props.headerText }}</h4>
  <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button> -->
</div>
<div class="modal-body">
  {{ props.contentText }}
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="cancel()">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="delete()">Delete</button>
</div>