import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { SentryErrorHandler } from './core/sentry.errorhandler';
import localeNlBe from '@angular/common/locales/nl-BE';

import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { ShellModule } from './shell/shell.module';
import { TalentScansModule } from './talentscans/talentscans.module';
import { JobScansModule } from './jobscans/jobscans.module';
import { ReflectionsModule } from './reflections/reflections.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import * as Sentry from '@sentry/browser';
import { environment } from 'src/environments/environment';
import { registerLocaleData } from '@angular/common';

import { GoogleTagManagerService } from 'angular-google-tag-manager';

Sentry.init({
  dsn: environment.sentry.dsn,
  ignoreErrors: [/ResizeObserver/]
});

registerLocaleData(localeNlBe);

@NgModule({
  imports: [
    BrowserModule,
    SharedModule,
    CoreModule,
    ShellModule,
    TalentScansModule,
    JobScansModule,
    ReflectionsModule,
    AppRoutingModule
  ],
  declarations: [AppComponent],
  providers: [{ provide: ErrorHandler, useClass: SentryErrorHandler },
  { provide: LOCALE_ID, useValue: 'nl-be' },
  { provide: 'googleTagManagerId', useValue: environment.googleTagManagerId }],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(gtmService: GoogleTagManagerService) {
    if (environment.production) {
      gtmService.addGtmToDom();
    }
  }
}
