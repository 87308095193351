<div class="expectations">
    <div class="logos__top2">
        <img
            *ngFor="let comp of top2Expectations"
            [src]="comp.iconUrl"
            [tooltip]="'expectations.' + comp.expectationId + '.name' | translate"
            placement="bottom"
        />
    </div>
    <div class="names">
        <div class="names__top2">{{ top2ExpectationsNames | translate }}</div>
        <div class="names__others" [tooltip]="otherExpectationsNames" placement="bottom" [adaptivePosition]="false" container="body">
            <!-- <span *ngFor="let exp of otherExpectationsNames; let indexExp = index">
                {{ exp | translate }}<span *ngIf="indexExp < otherExpectationsNames.length">, </span>
            </span> -->
            {{ otherExpectationsNames }}
        </div>
    </div>
</div>
