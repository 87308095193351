import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReflectionsRoutingModule } from './reflections-routing.module';
import { ReflectionsComponent } from './reflections/reflections.component';
import { SharedModule } from '../shared/shared.module';
import { ReflectionComponent } from './reflection/reflection.component';
import { ReflectionsFilterService } from './reflections-filter.service';

@NgModule({
  declarations: [ReflectionsComponent, ReflectionComponent],
  imports: [
    CommonModule,
    ReflectionsRoutingModule,
    SharedModule
  ],
  providers: [ReflectionsFilterService],
  entryComponents: [ReflectionsComponent]
})
export class ReflectionsModule { } 
