import { OnInit, OnDestroy } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../../environments/environment';
import { UserSettingsService } from '../../core/services/user-settings.service';
import { AccountsService } from '../../core/services/accounts.service';
var SidebarComponent = /** @class */ (function () {
    function SidebarComponent(msalService, userSettingsService, accountsService) {
        this.msalService = msalService;
        this.userSettingsService = userSettingsService;
        this.accountsService = accountsService;
        this.subscriptions = [];
    }
    SidebarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentUser = this.msalService.getUser();
        this.subscriptions.push(this.accountsService.accounts$.subscribe(function (accounts) { return _this.accounts = accounts; }));
    };
    SidebarComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (sub) { return sub.unsubscribe(); });
    };
    SidebarComponent.prototype.login = function () {
        this.msalService.loginRedirect(environment.adB2c.scopes, "ui_locales=nl");
    };
    SidebarComponent.prototype.logout = function () {
        this.userSettingsService.clear();
        this.msalService.logout();
    };
    SidebarComponent.prototype.changeToAccount = function (account) {
        this.userSettingsService.currentAccount$.next(account);
    };
    SidebarComponent.prototype.isAccountSelected = function (account) {
        var currentAccount = this.userSettingsService.currentAccount$.getValue();
        return !!currentAccount && currentAccount.id === account.id;
    };
    Object.defineProperty(SidebarComponent.prototype, "isLoggedIn", {
        get: function () {
            return !!this.msalService.getUser();
        },
        enumerable: true,
        configurable: true
    });
    return SidebarComponent;
}());
export { SidebarComponent };
