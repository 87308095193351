<div class="filter-container">
  <div class="filterbar" [ngClass]="{ 'action-bar-visible': actionBarVisible }" data-simplebar>
    <kazi-expectations-filter [name]="'filters.workvalues' | translate" [type]="1" [max]="3"
      [selection]="filter.workValues" (selectionChange)="workValuesChanged($event)">
    </kazi-expectations-filter>
    <kazi-expectations-filter [name]="'filters.teamroles' | translate" [type]="2" [max]="4"
      [selection]="filter.teamRoles" (selectionChange)="teamRolesChanged($event)">
    </kazi-expectations-filter>
    <kazi-domains-filter [name]="'filters.domains' | translate" [selection]="filter.domains"
      (selectionChange)="domainsChanged($event)">
    </kazi-domains-filter>
  </div>
  <div class="list" [ngClass]="{ 'action-bar-visible': actionBarVisible }" infiniteScroll [infiniteScrollThrottle]="500"
    [infiniteScrollContainer]="'.list'" [fromRoot]="true" (scrolled)="getReflectionScans()">
    <div class="list__header">
      <div class="list__header-count">
        <span translate [translateParams]="{ totalCount: totalCount }"
          [class.totalVisible]="totalCount > 0">jobscans.jobscans_found</span>
      </div>
      <div class="icon-input-container">
        <i class="fa fa-search"></i>
        <input type="text" [(ngModel)]="filter.search" (ngModelChange)="searchChanged($event)"
          [placeholder]="'filters.search_by_name_email' | translate" />
        <i *ngIf="filter.search" class="fa fa-close" (click)="clearSearch()"></i>
      </div>
    </div>
    <div class="list-cols">
      <div class="col-sm-3">
        <kazi-column-header [columnText]="'jobscans.job_title' | translate" [sortProp]="'title'" [currentSort]="sortOn"
          (sort)="sort($event)">
        </kazi-column-header>
      </div>
      <div class="header-col col-sm-1">
        <kazi-column-header [columnText]="'jobscans.date_added' | translate" [sortProp]="'createdOn'"
          [currentSort]="sortOn" (sort)="sort($event)">
        </kazi-column-header>
      </div>
      <div class="header-col col-sm-1">
        <kazi-column-header [columnText]="'jobscans.author' | translate" [sortProp]="'contactFirstName'"
          [currentSort]="sortOn" (sort)="sort($event)">
        </kazi-column-header>
      </div>
    </div>
    <hr class="list__header-separator" />
    <div *ngFor="let jobScan of reflectionScans">
      <kazi-reflection [jobScan]="jobScan" (deleted)="onDelete(jobScan)"></kazi-reflection>
      <hr class="list__item-separator" />
    </div>
    <kazi-spinner *ngIf="loading"></kazi-spinner>
  </div>
</div>