/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./column-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./column-header.component";
var styles_ColumnHeaderComponent = [i0.styles];
var RenderType_ColumnHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ColumnHeaderComponent, data: {} });
export { RenderType_ColumnHeaderComponent as RenderType_ColumnHeaderComponent };
export function View_ColumnHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "header-col"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "header-col--sorted": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "header-sorticons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "i", [["class", "fa fa-caret-up"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(9, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(10, { "hidden": 0 }), (_l()(), i1.ɵeld(11, 0, null, null, 3, "i", [["class", "fa fa-caret-down"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(13, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(14, { "hidden": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "header-col"; var currVal_1 = _ck(_v, 3, 0, _co.sortActive); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = "fa fa-caret-up"; var currVal_4 = _ck(_v, 10, 0, _co.ascendingSortIconHidden); _ck(_v, 9, 0, currVal_3, currVal_4); var currVal_5 = "fa fa-caret-down"; var currVal_6 = _ck(_v, 14, 0, _co.descendingSortIconHidden); _ck(_v, 13, 0, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.columnText; _ck(_v, 5, 0, currVal_2); }); }
export function View_ColumnHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kazi-column-header", [], null, null, null, View_ColumnHeaderComponent_0, RenderType_ColumnHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.ColumnHeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ColumnHeaderComponentNgFactory = i1.ɵccf("kazi-column-header", i3.ColumnHeaderComponent, View_ColumnHeaderComponent_Host_0, { columnText: "columnText", sortProp: "sortProp", currentSort: "currentSort" }, { sort: "sort" }, []);
export { ColumnHeaderComponentNgFactory as ColumnHeaderComponentNgFactory };
