import { BehaviorSubject } from 'rxjs';
var ReflectionsFilter = /** @class */ (function () {
    function ReflectionsFilter() {
        this.workValues = [];
        this.teamRoles = [];
        this.domains = [];
    }
    Object.defineProperty(ReflectionsFilter.prototype, "workValueIds", {
        get: function () {
            return this.workValues.length > 0 ? this.workValues.join(',') : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReflectionsFilter.prototype, "teamRoleIds", {
        get: function () {
            return this.teamRoles.length > 0 ? this.teamRoles.join(',') : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReflectionsFilter.prototype, "domainIds", {
        get: function () {
            return this.domains.length > 0 ? this.domains.join(',') : undefined;
        },
        enumerable: true,
        configurable: true
    });
    return ReflectionsFilter;
}());
export { ReflectionsFilter };
var ReflectionsFilterService = /** @class */ (function () {
    function ReflectionsFilterService() {
        this.filter$ = new BehaviorSubject(new ReflectionsFilter());
    }
    return ReflectionsFilterService;
}());
export { ReflectionsFilterService };
