import { Component, OnInit, OnDestroy } from '@angular/core';
import { IdentifyingClient, JobScanDto, DomainTranslatedDto, ExpectationDto } from '../../shared/api/identifying-api.generated';
import { AccountDto, ClientDto, AdministratorClient } from '../../shared/api/administrator-api.generated';
import { Subscription, combineLatest, BehaviorSubject, of as _observableOf, from } from 'rxjs';
import { filter, switchMap, map, distinctUntilChanged, catchError, mergeMap, debounceTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { UserSettingsService } from '../../core/services/user-settings.service';
import { PortalService } from '../../core/services/portal.service';
import { ExpectationTranslatedDto } from '../../shared/api/tagging-api.generated';
import { environment } from '../../../environments/environment';
import { BatchActionService, BatchSelectionType } from '../../core/services/batch-action.service';
import { DeleteModalComponent, DeleteModalProps } from '../../shared/components/delete-modal/delete-modal.component';
import { ReflectionsFilter, ReflectionsFilterService } from '../reflections-filter.service';

export type Expectation = ExpectationDto & { expectationTypeId: number, name: string, iconUrl: string };
export type JobScan = JobScanDto & { domains: DomainTranslatedDto[], expectations: Expectation[] };

@Component({
  selector: 'kazi-reflections',
  templateUrl: './reflections.component.html',
  styleUrls: ['./reflections.component.scss']
})
export class ReflectionsComponent implements OnInit, OnDestroy {

  reflectionScans: JobScan[];
  totalCount: number;
  sortOn: string;
  actionBarVisible: boolean;
  filter: ReflectionsFilter;
  loading: boolean;
  theme: string;

  private page = 1;
  private pageSize = environment.api.pageSize;
  private fetch$: BehaviorSubject<boolean>;
  private subscriptions: Subscription[];

  constructor(private identifyingClient: IdentifyingClient,
    private administratorClient: AdministratorClient,
    private userSettingsService: UserSettingsService,
    private portalService: PortalService,
    private batchActionService: BatchActionService,
    private modalService: BsModalService,
    private translate: TranslateService,
    private reflectionsFilterService: ReflectionsFilterService) {
    this.reflectionScans = [];
    this.subscriptions = [];

    this.fetch$ = new BehaviorSubject(false);
    this.sortOn = '-CreatedOn';
  }

  ngOnInit() {

    const clientsSub = combineLatest(
      this.userSettingsService.currentAccount$)
      .pipe(
        filter(([account]) => fetch && !!account),
        debounceTime(500),
        switchMap(([account]:
          [AccountDto]) => {
          this.loading = true;
          return this.administratorClient.getClientsForAccount(
            account.id).pipe(
              map((clients: ClientDto[]) => {
                return clients;
              }),
              catchError(err => _observableOf([]))
            );
        }),
      ).subscribe(clients => {
        this.loading = false;
        this.theme = clients[0].theme;
        this.fetch$.next(false);
      });

    const reflectionsSub = combineLatest(this.fetch$,
      this.reflectionsFilterService.filter$,
      this.userSettingsService.currentAccount$,
      this.portalService.expectations$,
      this.portalService.domains$)
      .pipe(
        filter(([fetch, reflectionsFilter, account, expectations, domains]) => fetch && !!account && !!expectations && !!domains),
        debounceTime(500),
        switchMap(([fetch, reflectionsFilter, account, expectation, domains]:
          [boolean, ReflectionsFilter, AccountDto, ExpectationTranslatedDto[], DomainTranslatedDto[]]) => {
          const offset = (this.page - 1) * this.pageSize;
          this.loading = true;
          return this.administratorClient.getJobScansForAccountAndClientType(
            account.id,
            7,
            undefined,  /* externalRef */
            undefined /* shouldHaveExpectations */,
            true /* includeExpectations */,
            reflectionsFilter.teamRoleIds, reflectionsFilter.workValueIds,
            offset, this.pageSize, this.sortOn, reflectionsFilter.search, reflectionsFilter.domainIds).pipe(
              map((reflectionScans: JobScan[]) => {
                reflectionScans.forEach(jp => {
                  jp.expectations = this.mapExpectations(jp, expectation);
                  jp.domains = this.mapDomains(jp, domains);
                });
                return reflectionScans;
              }),
              catchError(err => _observableOf([]))
            );
        }),
      ).subscribe(reflectionScans => {
        this.totalCount = reflectionScans.length;
        this.loading = false;
        Array.prototype.push.apply(this.reflectionScans, reflectionScans);
        this.fetch$.next(false);
      });

    const accountSub = this.userSettingsService
      .currentAccount$
      .pipe(filter(p => !!p), distinctUntilChanged())
      .subscribe(account => this.reset());

    const batchDeleteSub = this.batchActionService
      .delete$
      .pipe(filter(res => res.selectionType === BatchSelectionType.Reflections))
      .subscribe(res => this.showBatchDeleteConfirmationModal(res.selection));

    const batchActionBarVisibleSub = this.batchActionService
      .actionBarVisible$
      .subscribe(actionBarVisible => this.actionBarVisible = actionBarVisible);

    const filterSub = this.reflectionsFilterService.filter$
      .subscribe(reflectionScansFilter => {
        this.filter = reflectionScansFilter;
        this.reset();
      });

    const totalCountSub = combineLatest(this.userSettingsService.currentAccount$,
      this.reflectionsFilterService.filter$)
      .pipe(
        filter(([account, reflectionScansFilter]) => !!account),
        switchMap(([account, reflectionScansFilter]: [AccountDto, ReflectionsFilter]) =>
          _observableOf<number>(this.totalCount)
        )
      ).subscribe(totalCount => this.totalCount = totalCount);

    this.subscriptions.push(clientsSub);
    this.subscriptions.push(reflectionsSub);
    this.subscriptions.push(accountSub);
    this.subscriptions.push(batchDeleteSub);
    this.subscriptions.push(batchActionBarVisibleSub);
    this.subscriptions.push(filterSub);
    this.subscriptions.push(totalCountSub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getReflectionScans() {
    this.page++;
    this.fetch$.next(true);
  }

  sort(sortOn: string) {
    this.sortOn = sortOn;
    this.reset();
  }

  onDelete(reflectionScan: JobScan) {
    const index = this.reflectionScans.indexOf(reflectionScan);
    if (index >= 0) {
      this.reflectionScans.splice(index, 1);
      this.totalCount--;
    }
  }

  workValuesChanged(workValues: string[]) {
    this.filter.workValues = workValues;
    this.reflectionsFilterService.filter$.next(this.filter);
  }

  teamRolesChanged(teamRoles: string[]) {
    this.filter.teamRoles = teamRoles;
    this.reflectionsFilterService.filter$.next(this.filter);
  }

  domainsChanged(domains: string[]) {
    this.filter.domains = domains;
    this.reflectionsFilterService.filter$.next(this.filter);
  }

  clearSearch() {
    this.filter.search = '';
    this.searchChanged('');
  }

  searchChanged(search: string) {
    this.reflectionsFilterService.filter$.next(this.filter);
  }

  private reset() {
    this.batchActionService.deSelectAll();
    this.page = 1;
    this.reflectionScans.splice(0);
    this.totalCount = 0;
    this.fetch$.next(true);
  }

  private showBatchDeleteConfirmationModal(reflectionScanIds: Array<string>) {
    const count = reflectionScanIds.length;
    const header = this.translate.instant('jobscans.delete_multiple_modal_header', { count });
    const message = this.translate.instant('jobscans.delete_multiple_modal_content', {
      count,
      accountName: this.userSettingsService.currentAccount$.getValue().name
    });
    const props = new DeleteModalProps(header, message);
    const initialState = { props };

    this.modalService.show(DeleteModalComponent, { initialState });

    const deleteSub = props.confirm$.subscribe(() => this.deleteReflectionScans(reflectionScanIds));
    this.subscriptions.push(deleteSub);
  }

  private deleteReflectionScans(reflectionScanIds: Array<string>) {
    this.identifyingClient.deleteJobScans(reflectionScanIds.join(','))
      .subscribe(() => {
        this.batchActionService.deSelectAll();
        this.batchActionService.actionBarVisible$.next(false);

        reflectionScanIds.forEach(id => {
          const reflectionScan = this.reflectionScans.filter(t => t.id === id)[0];
          this.onDelete(reflectionScan);
        });
      });
  }

  private mapExpectations(reflectionScan: JobScan, allExpectations: ExpectationTranslatedDto[]): Expectation[] {
    if (!reflectionScan.expectations) {
      return [];
    }

    const lang = this.translate.currentLang;
    return reflectionScan.expectations.map((exp: Expectation) => {
      const translatedExpectation = allExpectations.filter(c => c.id === exp.expectationId)[0];
      exp.expectationTypeId = translatedExpectation.expectationTypeId;
      exp.name = translatedExpectation.name[lang];
      exp.iconUrl = translatedExpectation.iconUrl.replace("kazi", this.theme);
      return exp;
    });
  }

  private mapDomains(reflectionScan: JobScan, allDomains: DomainTranslatedDto[]): DomainTranslatedDto[] {
    if (!reflectionScan.domainIds) {
      return [];
    }

    return reflectionScan.domainIds.map(domainId => allDomains.filter(dom => dom.id === domainId)[0]);
  }
}
