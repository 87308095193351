import { AdministratorClient } from '../../shared/api/administrator-api.generated';
import { BehaviorSubject, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserSettingsService } from './user-settings.service';
import * as R from 'ramda';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/api/administrator-api.generated";
import * as i2 from "./user-settings.service";
var AccountsService = /** @class */ (function () {
    function AccountsService(administratorClient, userSettingsService) {
        this.administratorClient = administratorClient;
        this.userSettingsService = userSettingsService;
        this.accounts$ = new BehaviorSubject([]);
        this.accountsLoaded$ = new BehaviorSubject(false);
    }
    AccountsService.prototype.loadAccounts = function () {
        var _this = this;
        var getAccounts$ = this.administratorClient
            .getMyActiveAccounts()
            .pipe(catchError(function (error) { return of([]); }));
        getAccounts$.subscribe(function (accounts) {
            var sortAccountsByName = R.sortBy(R.compose(R.toLower, R.prop('name')));
            var sortedAccounts = sortAccountsByName(accounts);
            _this.accounts$.next(sortedAccounts);
            _this.userSettingsService.restore();
            if (!_this.userSettingsService.isAccountSelected() &&
                sortedAccounts.length > 0) {
                _this.userSettingsService.currentAccount$.next(sortedAccounts[0]);
            }
            _this.accountsLoaded$.next(true);
        });
        return getAccounts$;
    };
    AccountsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountsService_Factory() { return new AccountsService(i0.ɵɵinject(i1.AdministratorClient), i0.ɵɵinject(i2.UserSettingsService)); }, token: AccountsService, providedIn: "root" });
    return AccountsService;
}());
export { AccountsService };
