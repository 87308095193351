<div class="list-item">
  <div class="list-item__checkbox">
      <label class="checkbox-container">
        <input
          type="checkbox"
          [ngModel]="isSelected()"
          (click)="select()"
        />
        <span class="checkmark darkblue"></span>
      </label>
    </div>
    <div class="list-item__prop--primary col-sm-3" (click)="select()">
      <div>{{ jobScan.title }}</div>
      <div class="secondary-text">{{ domains }}</div>
    </div>
    <div class="list-item__prop--secondary col-sm-1">{{ jobScan.createdOn | date:'mediumDate':'nl-be' }}</div>
    <div class="list-item__prop--secondary col-sm-1">{{ jobScan.contactFirstName }} {{ jobScan.contactLastName }}</div>
    <div class="col-sm-3">
      <kazi-expectations [expectations]="jobScan.expectations" [type]="workValuesExpectationTypeId"></kazi-expectations>
    </div>
    <div class="col-sm-3">
      <kazi-expectations [expectations]="jobScan.expectations" [type]="teamRolesExpectationTypeId"></kazi-expectations>
    </div>
    <div class="list-item__actions">
        <div class="action-icon">
          <i *ngIf="hasExpectations" class="fa fa-compress view-detail-job" (click)="showReport($event, jobScan.id)" [tooltip]="'actions.view_report' | translate" placement="bottom"></i>
        </div>
        <div class="action-icon">
          <i *ngIf="hasExpectations" class="fa fa-print print-detail-job" (click)="printReport($event, jobScan.id)" [tooltip]="'actions.print_report' | translate" placement="bottom"></i>
        </div>
        <div class="action-icon">
          <i class="fa fa-trash delete-detail-job" (click)="showDeleteConfirmationModal($event, jobScan.id)" [tooltip]="'actions.remove' | translate" placement="bottom"></i>
        </div>
    </div>
</div>
